$(function () {
  

    $('.backtotop').click(function () {
        $('body').animate({
            scrollTop: 0
        }, 200);
    });
    $(window).scroll(function () {
        let top = $(this).scrollTop();
        if (top > 300) {
            $('.backtotop').show();
        } else {
            $('.backtotop').hide();
        }
    });


});